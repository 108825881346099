/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Box, Heading, Link} from "@chakra-ui/react";
import {StackIconsGroup} from "components";
import {ExternalLinkIcon} from "@chakra-ui/icons";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img",
    h2: "h2",
    h3: "h3",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/images/projects/featured/modalero-shop.png",
    alt: "showcase image of web ui "
  })), "\n", React.createElement(_components.h2, null, "Summary"), "\n", React.createElement(Heading, {
    as: "div",
    size: "md"
  }, React.createElement(_components.p, null, "Prototype a shopping experience for Modalero shop. Design and develop an accessible, engaging and intuitive experience. Focused on facilitating the discovery of new products, exploring of previous interest and, enabling informed purchase decisions.")), "\n", React.createElement(Box, {
    mb: 6
  }, React.createElement(Link, {
    asExternal: true,
    variant: "linkOne",
    href: props.pageContext.frontmatter.link,
    target: "_blank"
  }, props.pageContext.frontmatter.title, React.createElement(ExternalLinkIcon, {
    mx: "2px"
  }))), "\n", React.createElement(Link, {
    asExternal: true,
    variant: "linkOne",
    href: props.pageContext.frontmatter.repo,
    target: "_blank"
  }, props.pageContext.frontmatter.title, " - github", React.createElement(ExternalLinkIcon, {
    mx: "2px"
  })), "\n", React.createElement(_components.h3, null, "Stack"), "\n", React.createElement(StackIconsGroup, {
    iconArr: ["react", "sass", "netlify", "github"]
  }), "\n", React.createElement(_components.h3, null, "Results"), "\n", React.createElement(_components.p, null, "An enhanced UX and UI designed in Figma and build with React home page preview ready for integration with Shopify API."), "\n", React.createElement(_components.h3, null, "Deliverables"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "website"), "\n", React.createElement(_components.li, null, "user experience design"), "\n", React.createElement(_components.li, null, "web design"), "\n", React.createElement(_components.li, null, "development"), "\n", React.createElement(_components.li, null, "user interface design"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
